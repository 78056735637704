// Toggle between http and https depending if ssl key is available
const DEFAULT_PROTOCOL = 'https';

export const build = {
  API_HOST: DEFAULT_PROTOCOL + '://config.api.ubicquia.com/api/',
  SITE_URL: 'http://config.swagger.ubicquia.com/',
  LOGO: 'logo',

  // Options: en (English)
  DEFAULT_LANG: 'en',
};
